import React from 'react';
import { Parallax } from 'react-parallax';
import { Box, Typography } from '@mui/material';
import heroImage from '../assets/images/hero.jpg';

const HeroSection: React.FC = () => {
  return (
    <Parallax bgImage={heroImage} strength={500}>
      <Box
        id="inicio"
        sx={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          textAlign: 'center',
        }}
      >
        <Typography variant="h2"><b>Bienvenidos a Guacamolín</b></Typography>
      </Box>
    </Parallax>
  );
};

export default HeroSection;
