import React from 'react';
import { Parallax } from 'react-parallax';
import { Box, Grid, Typography } from '@mui/material';
import heroImage from '../assets/images/Aztecas.jpg';
import { Opacity } from '@mui/icons-material';

const HeroSection: React.FC = () => {
  return (
    <Parallax bgImage={heroImage}  strength={500} bgImageStyle={{ opacity: 0.4 }}>
      <Box
        id="hero"



      sx={{height: {xs:'80vh',md:'50vh'},
        display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'black',
          textAlign: 'center',
      }}
      >
        <Grid>
          <Grid item xs={12}>        <Typography variant="h4" sx={{padding:1}}><b>Origen del aguacate</b></Typography>
          </Grid>
          <Grid item xs={12}>  
        <Typography mx={{padding:8}} sx={{padding:2}}><b>Los aztecas fueron los primeros en concebir esta comida, que le llamaron āhuacamolli, lo cual se traduce como “salsa de aguacate”. Se empezó a elaborar antes del siglo XV, ya que el aguacate es un alimento originario de la zona central y sur de México.

Cuando los conquistadores españoles llegaron a las Américas se quedaron encantados con el plato. Como el aguacate no se cultivaba en Europa, intentaron prepararlo con otros alimentos, pero el sabor no era satisfactorio y finalmente decidieron traer el aguacate a Europa para su cultivo.</b></Typography>
</Grid>
</Grid>
      </Box>
    </Parallax>
  );
};

export default HeroSection;
