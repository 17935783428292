import React from 'react';
import { Button } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';

const InstagramButton = () => {
  return (
    <Button
      variant="contained"
      sx={{
        margin: 2,
        background: 'linear-gradient(45deg, #F58529, #DD2A7B, #8134AF, #515BD4)',
        color: 'white',
        '&:hover': {
          background: 'linear-gradient(45deg, #515BD4,#8134AF,#DD2A7B,#F58529)',
          opacity: 0.9,
        },
      }}
      href="https://www.instagram.com/guacamolinmx/?igsh=eW85MGtzNm9wZ202"
      target="_blank"
      startIcon={<InstagramIcon />}
    >
      Visítanos en Instagram
    </Button>
  );
};

export default InstagramButton;
