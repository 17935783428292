import React from 'react';
import { Box, Container, Grid, Typography, Card, CardMedia, CardContent,Divider } from '@mui/material';
import i1 from '../assets/images/1.jpg';
import i2 from '../assets/images/2.jpg';
import i3 from '../assets/images/3.jpg';
import i4 from '../assets/images/Tradicionalguacamole.png';
import i5 from '../assets/images/jalapeñoguacamole.png';
import i6 from '../assets/images/Habaneroguacamole.png';

const products = [
  {
    title: 'Guacamole Tradicional',
    description: (<Typography>
      Guacamole cremoso, al puro estilo tradicional mexicano<br/><Divider/>
       Con ingredientes frescos: Aguacate Hass, Tomate freso, Cebolla fresca, Sal,
       Limón, Ajo, Cilantro, Ácido Cítrico y Ácido Ascórbico.
    </Typography>),
    image: i4,
    nutrition: (<Typography sx={{textAlign:'start'}}><b>INFORMACION NUTRIMENTAL</b> <br/>
          <Divider />

    <b>Porciones por envase:</b> 8<br/>
    <b>Tamaño de Porción:</b> 30g<br/><br/>
    <b>Cantidad por porcion:</b><br/>
    <Divider />
      <b>Calorías:</b> 50<br/>
      <b>Grasas totales:</b> 4g<br/>
      <b>Grasas saturadas:</b> 1g<br/>
      <b>Grasas Trans:</b> 0mg<br/>
      <b>Sodio:</b> 80mg<br/>
      <b>Carbohidratos totales:</b> 3g<br/>
      <b>Azucar:</b> 0g<br/>
      <b>Proteína:</b> menor a 1g<br/>
      <b>Vitamina A:</b> 1%<br/>
      <b>Hierro:</b> 3%<br/>
      <b>Calcio:</b> 2%<br/>
      <b>Potasio:</b> 13%<br/></Typography>),
      
  },
  {
    title: 'Guacamole Cilantro Jalapeño',
    description: (<Typography>
      Guacamole cremoso con cilantro y jalapeño<br/><Divider/>
       Con ingredientes frescos: Aguacate Hass, Cilantro IQF, Pimiento Rojo, Cebolla,
       Sal, Puré de jalapeño, Ajo, Ácido Cítrico y Ácido Ascórbico.
    </Typography>),
    image: i5,
    nutrition: (<Typography sx={{textAlign:'start'}}><b>INFORMACION NUTRIMENTAL</b> <br/>
          <Divider />

    <b>Porciones por envase:</b> 8<br/>
    <b>Tamaño de Porción:</b> 30g<br/><br/>
    <b>Cantidad por porcion:</b><br/>
    <Divider />
      <b>Calorías:</b> 55<br/>
      <b>Grasas totales:</b> 4g<br/>
      <b>Grasas saturadas:</b> 1g<br/>
      <b>Grasas Trans:</b> 0mg<br/>
      <b>Colesterol:</b> 0mg<br/>
      <b>Sodio:</b> 90mg<br/>
      <b>Carbohidratos totales:</b> 2g<br/>
      <b>Azucar:</b> 0g<br/>
      <b>Fibra dietética:</b> 0g<br/>
      <b>Proteína:</b> menor a 1g<br/>
      <b>Vitamina A:</b> 1%<br/>
      <b>Hierro:</b> 3%<br/>
      <b>Calcio:</b> 2%<br/>
      <b>Potasio:</b> 13%<br/></Typography>),
      
  },
  {
    title: 'Guacamole habanero',
    description: (<Typography>
      Guacamole cremoso con habanero<br/><Divider/>
       Con ingredientes frescos: Aguacate Hass, Cebolla, Chile habanero,
       Limón, Ajo ,Cilantro , Ácido Cítrico y Ácido Ascórbico.
    </Typography>),
    image: i6,
    nutrition: (<Typography sx={{textAlign:'start'}}><b>INFORMACION NUTRIMENTAL</b> <br/>
          <Divider />

    <b>Porciones por envase:</b> 8<br/>
    <b>Tamaño de Porción:</b> 30g<br/><br/>
    <b>Cantidad por porcion:</b><br/>
    <Divider />
      <b>Calorías:</b> 60<br/>
      <b>Grasas totales:</b> 4g<br/>
      <b>Grasas saturadas:</b> 1g<br/>
      <b>Grasas Trans:</b> 0mg<br/>
      <b>Colesterol:</b> 0mg<br/>
      <b>Sodio:</b> 85mg<br/>
      <b>Carbohidratos totales:</b> 2g<br/>
      <b>Azucar:</b> 0g<br/>
      <b>Fibra dietética:</b> 1g<br/>
      <b>Proteína:</b> menor a 1g<br/>
      <b>Vitamina A:</b> 1%<br/>
      <b>Hierro:</b> 3%<br/>
      <b>Calcio:</b> 2%<br/>
      <b>Potasio:</b> 13%<br/></Typography>),
      
  }
];

const ProductsSection: React.FC = () => {
  return (
    <Box id="productos" sx={{ p: 5, backgroundColor: '#f4f4f4' }}>
      <Container sx={{p:1}}>
        <Typography variant="h4" align="center" gutterBottom>
          <b> Nuestros Productos</b>
        </Typography>
        <Grid container spacing={2} mt={2}>
          {products.map((product, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  perspective: '1000px',
                  width: '100%',
                  height: '450px',
                  '&:hover .flip-card-inner': {
                    transform: 'rotateY(180deg)',
                  },
                }}
              >
                <Box
                  className="flip-card-inner"
                  sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    transition: 'transform 0.6s',
                    transformStyle: 'preserve-3d',
                    borderRadius: '8px',
                  }}
                >
                  <Card
                    className="flip-card-front"
                    sx={{
                      backfaceVisibility: 'hidden',
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      borderRadius: '8px',
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      zIndex: 2,
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="200"
                      image={product.image}
                      alt={product.title}
                      sx={{ objectFit: 'scale-down' }}
                    />
                    <CardContent sx={{ textAlign: 'center' }}>
                      <Typography variant="h6">{product.title}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {product.description}
                      </Typography>
                    </CardContent>
                  </Card>

                  <Card
                    className="flip-card-back"
                    sx={{
                      backfaceVisibility: 'hidden',
                      transform: 'rotateY(180deg)',
                      position: 'absolute',
                      width: '100%',
                      height: '450px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '8px',
                      textAlign: 'center',
                      padding: 2,
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      zIndex: 1,
                    }}
                  >
                    <Typography>{product.nutrition}</Typography>
                  </Card>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ProductsSection;
