import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link as ScrollLink } from 'react-scroll';
import { Box } from '@mui/material';
import logo from '../assets/images/Logo.png';

const Navbar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleLinkClick = () => {
    setDrawerOpen(false); // Cierra el drawer al hacer clic en un link
  };

  const drawerItems = (
    <Box
      sx={{ width: 250, backgroundColor: 'rgba(44, 98, 3)', height: '100%', color: 'white' }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {['Inicio', 'Productos', 'Artículos', 'Contacto'].map((text) => (
          <ListItem button key={text}>
            <ScrollLink to={text.toLocaleLowerCase()} smooth duration={500} onClick={handleLinkClick}>
              <ListItemText primary={text} />
            </ScrollLink>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="fixed" style={{ backgroundColor: 'rgba(44, 98, 3,.9)' }}>
        <Toolbar>
          <Box>
            <img src={logo} height={60} style={{ marginTop: 4 }} alt="Logo" />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <ScrollLink to="inicio" smooth duration={500}>
              <Button color="inherit" style={{ fontSize: 20 }}><b>Inicio</b></Button>
            </ScrollLink>
            <ScrollLink to="productos" smooth duration={500}>
              <Button color="inherit" style={{ fontSize: 20 }}><b>Productos</b></Button>
            </ScrollLink>
            <ScrollLink to="artículos" smooth duration={500}>
              <Button color="inherit" style={{ fontSize: 20 }}><b>Artículos</b></Button>
            </ScrollLink>
            <ScrollLink to="contacto" smooth duration={500}>
              <Button color="inherit" style={{ fontSize: 20 }}><b>Contacto</b></Button>
            </ScrollLink>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none', width: '100%' } }}>
            <IconButton
              sx={{ ml: 'auto' }}
              color="inherit"
              edge="start"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              {drawerItems}
            </Drawer>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
