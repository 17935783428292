import React from 'react';
import { Box, Grid, Typography, Card, CardMedia, CardContent } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import imagen from '../assets/images/Aguacate.jpg';
import { useInView } from 'react-intersection-observer';

const BackgroundImage = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '400px', // Ajusta la altura según sea necesario
  backgroundImage: `url(${imagen})`, // Reemplaza con la ruta a tu imagen
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
});

const Overlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay negro con 50% de opacidad
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
});

// Animación de entrada de izquierda a derecha
const slideIn = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  50% {
    transform: translateX(10%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Animación de rebote
const bounce = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

const DecorativeLine = styled(Typography)(({ theme }) => ({
  display: 'flex',
  opacity: 0,
  transform: 'translateX(-100%)',
  transition: 'opacity 0.5s ease-in-out',
  '&.visible': {
    opacity: 1,
    transform: 'translateX(0)',
    animation: `${slideIn} 1.5s ease-out, ${bounce} 1s ease-in-out 1.5s`,
  },
  '&::before, &::after': {
    content: '"🥗🌮🌳🥑🌿🌮 "',
    flex: '1',
    textAlign: 'center',
  },
  '&::before': {
    marginRight: theme.spacing(1),
  },
  '&::after': {
    marginLeft: theme.spacing(1),
  },
  // Consultas de medios para ajustar la cantidad de emojis
  '@media (max-width: 1200px)': {
    '&::before': {
      content: '"🥗🌳🥑🌿"',
    },
    '&::after': {
      content: '"🥑🌿🌮🥗"',
    },
  },
  '@media (max-width: 900px)': {
    '&::before': {
      content: '"🥗🥑🌳"',
    },
    '&::after': {
      content: '"🥑🌿🌮"',
    },
  },
  '@media (max-width: 600px)': {
    '&::before': {
      content: '"🥑"',
    },
    '&::after': {
      content: '"🥑"',
    },
  },
}));


const TeamSection: React.FC = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Solo se activa una vez
    threshold: 0.5, // Se activa cuando el 50% del elemento es visible
  });

  return (
    <Box id="team" sx={{ pt: 5 }}>
      <Grid container  > {/* Aplicar el ref aquí */}
        <Grid item marginBottom={3} textAlign={'center'} width='100%' ref={ref}>
          <DecorativeLine
            variant="h4"
            gutterBottom
            className={inView ? 'visible' : ''} // Aplicar clase condicionalmente
          >
          <b>  ¿Quiénes somos?</b>
          </DecorativeLine>
        </Grid>
        <BackgroundImage>
          <Overlay>
            <Grid item justifyContent={'center'}>
              <Typography sx={{fontSize: {
          xs: '18px', // Tamaño de fuente para pantallas extra pequeñas
          sm: '18px', // Tamaño de fuente para pantallas pequeñas
          md: '20px', // Tamaño de fuente para pantallas medianas
          lg: '24px', // Tamaño de fuente para pantallas grandes
          xl: '28px', // Tamaño de fuente para pantallas extra grandes
        }}} >
                Guacamolin es una empresa orgullosamente de origen Méxicano en constante crecimiento gracias a nuestro guacamole auténtico y de alta calidad que fomenta una
                alimentación saludable y deliciosa en cada hogar.
                <br />
                <br />
                Buscamos expandirnos nacional e internacionalmente innovando en la manera que presentamos
                y distribuimos nuestro producto reduciendo ademas nuestro impacto ambiental.
              </Typography>
            </Grid>
          </Overlay>
        </BackgroundImage>
        
      </Grid>
    </Box>
  );
};

export default TeamSection;
