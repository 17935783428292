import React from 'react';
import { Box, Container, Typography, Grid, Paper } from '@mui/material';
import fondo from '../assets/images/Logo.png';




const ArticlesSection: React.FC = () => {
  return (
    <Box id="artículos" sx={{ py: 5}}>
      <Container >
    
        <Grid container spacing={4}>
        <Grid item xs={12} md={4} container justifyContent="center" alignItems="center">
        <Paper elevation={3} sx={{ p: 3, borderColor:'rgba(109,189,68,.5)',borderStyle:'solid',borderRadius:0 ,borderWidth:1}}>
                <Typography variant="h6">¿Que aporta el Aguacate?</Typography>
                <Typography variant="body2" color="textSecondary" >
                <p>Nutrientes.<br/>
Grasas Saludables.<br/>
Fibra.<br/>
Antioxidantes.<br/>
Salud al Corazón.<br/>
Mejora la absorción de Nutrientes.<br/>
Es Antiinflamatorio.<br/>
Mejora la Piel y el Cabello.<br/>
Ayuda a controlar el Azúcar.<br/>
Variedad a tus comidas. <br/>
<h4>¡Incluye aguacate en tu dieta y aprovecha sus múltiples beneficios!</h4></p>
                </Typography>

              </Paper>
</Grid>
<Grid item xs={12} md={4} container justifyContent="center" alignItems="center">
<Typography variant="h4" align="center" marginBottom={5} gutterBottom >
     <b>   🥑 Aguacate  🥑<br/>❤️<br/>🥗 Guacamole 🥗</b>
        </Typography>
<img src={fondo} width={'200'} ></img>
          </Grid>
          <Grid item xs={12} md={4} container justifyContent="center" alignItems="center" >

    
            <Grid item margin={2} md={12} >
              <Paper elevation={3} sx={{ p: 3,  borderColor:'rgba(109,189,68,.5)',borderStyle:'solid',borderRadius:0,borderWidth:1}}>
                <Typography variant="h6">Algunas comidas que para preparar con Guacamole</Typography>
                <Typography variant="body2" color="textSecondary" >
                <p >
                Tacos.<br/>
Nachos.<br/>
Quesadillas.<br/>
Enchiladas.<br/>
Hamburguesas.<br/>
Tostadas.<br/>
Papas fritas o camotes fritos.<br/>
Huevos rancheros.<br/>
Burritos.<br/>
Chiles rellenos.
                </p>
                </Typography>

              </Paper>
            </Grid>
     
          </Grid>

          
        </Grid>
      </Container>
    </Box>
  );
};

export default ArticlesSection;
