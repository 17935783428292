import React from 'react';
import { Button } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';

const FacebookButton = () => {
  return (
    <Button
      variant="contained"

      sx={{margin:2,backgroundColor:'#0866FF',
        '&:hover': {
          background: 'white',
          color:'#0866FF',
          opacity: 0.9,
        }
      }}
      color="primary"
      href="https://www.facebook.com/people/Guacamol%C3%ADn-MX/61564099269031/"
      target="_blank"
      startIcon={<FacebookIcon />}

    >
      Visítanos en Facebook
    </Button>
  );
};

export default FacebookButton;