import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import ProductsSection from './components/ProductsSection';
import Historisection from './components/HistoriaAguacate';
import ArticlesSection from './components/ArticlesSection';
import ContactSection from './components/ContactSection';
import TeamSection from './components/TeamSection';
import theme from './styles/theme';
import FloatingButton from './components/FloatingButton';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navbar />
      <HeroSection />
      <TeamSection />

      <ProductsSection />
      <Historisection />
      <ArticlesSection />
      <ContactSection />
      <FloatingButton/>

    </ThemeProvider>
  );
};

export default App;
