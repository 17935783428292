import React from 'react';
import { Box, Container, Typography, TextField, Button, Grid } from '@mui/material';
import Fb from './fb';
import Ig from './ig';

const ContactSection: React.FC = () => {
  return (
    <Box id="contacto" sx={{ py: 5, backgroundColor:'rgba(44, 98, 3)', color:'white  ' }}>
      <Container>
        
        <Grid container>
<Grid item xs={12}><Typography variant="h4" align="center" gutterBottom>
          <b>Datos de contacto</b>
        </Typography></Grid>
        <Grid item xs={12} md={6} sx={{mt:'auto',mb:'auto'}}><Typography><b>Telefono de contacto:</b> 452 164 7077</Typography>
        <Typography><b>Correo electrónico:</b> ventas@guacamolin.com.mx</Typography></Grid>
        <Grid item spacing={2} xs={12} md={6} sx={{textAlign:'center'}}><Typography sx={{m:2}}><b>Redes sociales</b></Typography>
      <Fb /><br/>
      <Ig />

        </Grid>
        <Grid item xs={12} textAlign={'center'} padding={2}><Typography>GUACAMOLÍN® 2024</Typography></Grid>

        </Grid>
        
      </Container>
    </Box>
  );
};

export default ContactSection;
