import React from 'react';
import { Fab } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { styled, keyframes } from '@mui/system';
import guacamolin from '../assets/images/guacamolín.png'

const StyledFab = styled(Fab)({
  position: 'fixed',
  bottom: 16,
  right: 16,
  background:`url(${guacamolin})`,
  backgroundSize:'80%',
   backgroundPosition: 'center',
   backgroundRepeat:'no-repeat'

});


const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const FloatingArrow = styled(ArrowUpwardIcon)({
  position: 'fixed',
  bottom: 70, // Ajusta esta distancia según sea necesario
  right: 30,  // Alinea con el botón
  animation: `${bounce} 2s infinite`,
  fontSize: '2rem', // Tamaño de la flecha
  color:'#99533d',

});

const FloatingButton: React.FC = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
        <>
      <FloatingArrow />
      <StyledFab onClick={scrollToTop} />
    </>

  );
};

export default FloatingButton;
